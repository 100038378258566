.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-text {
    color: #fff;
    text-align: center;
  }
  
  .loading-text h1 {
    font-size: 5rem;
    margin: 0;
  }
  
  .loading-text h2 {
    font-size: 1.6rem;
  }
  
  .typewriter {
    display: inline-block;
    overflow: hidden;
    margin-top: .4rem !important;
  }

  h5 {
    letter-spacing: 2px;
  }
  
  .typewriter h5 {
    color: #fff;
    border-right: 0.15em solid #fff;
    padding-right: 0.5em;
    animation: typing 2.5s steps(15) infinite;
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 6.5em;
    }
  }
  