.App {
  text-align: center;
}

@font-face {
  font-family: 'Blanch Condensed';
  src: url('./fonts/blanch_condensed-webfont.ttf') format('opentype');
  font-weight: normal;
}

body {
  font-family: 'Blanch Condensed', sans-serif;
  transition: 5s all;
}

h1 {
  font-family: 'Blanch Condensed', sans-serif;
}

h1, h2, h3, h4, h5 {
  letter-spacing: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.rain {
  font-size: 2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.enters-button {
  padding: 1rem 2rem;
  border: 2px solid #fff;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* font-size: .5rem; */
  font-weight: bold;
  text-transform: uppercase;
  background-image: linear-gradient(to right, #ff8a00, #ff0084, #e60073, #ff3300);
  transition: background 1s;
} 

.enter-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 600px) {
  .enter-button::before {
    content: "\2192" !important;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
  }  
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
}

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

button {
  --border-radius: 50%;
  --border-width: 4px;

  appearance: none;
  position: relative;
  width: 5em;
  height: 5em;
  border: 0;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 3em;
  font-weight: 600;
  color: #fff;
}

button::after {
  --m-i: linear-gradient(#000, #000);
  --m-o: content-box, padding-box;

  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: var(--border-width);
  border-radius: var(--border-radius);
  background-image: conic-gradient(
    #488cfb,
    #29dbbc,
    #ddf505,
    #ff9f0e,
    #e440bb,
    #655adc,
    #488cfb
  );

  mask-image: var(--m-i), var(--m-i);
  mask-origin: var(--m-o);
  mask-clip: var(--m-o);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;

  filter: hue-rotate(0);
  animation: hue linear 1500ms infinite;
  animation-play-state: paused;
}

button::after {
  animation-play-state: running;
  transition: border-width 4s ease;

}

button:hover::after {
  /* --border-width: 10px; */
  animation-play-state: running;

}

.enter-button:hover::before {
  content: "\2192";
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}



.form-button:hover {
  --border-width: 10px !important;
}

.enter-button::before {
  content: "";
}

@keyframes hue {
	to {
		filter: hue-rotate(1turn);
	}
}

.text-container {
  width: 100vw;
  max-width: 700px;
  margin: 20px 20px;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}