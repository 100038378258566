@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-5xl;
    letter-spacing: 5px;

  }
  h2 {
    @apply text-xl;
    padding-bottom: 10px;
  }
  h5 {
    @apply text-lg;
    letter-spacing: 5px;
  }
  p {
    padding-bottom: 20px;
  }
}