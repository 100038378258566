.chip {
    --border-radius: 50%;
    padding: 5px;
    --border-width: 4px;
    margin: 10px;
    min-width: 95px;
    width: 95px;
    height: 95px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    appearance: none;
    font-size: 12px;
    position: relative;
    border: 0;
    background-color: transparent;
    color: #fff;
  }

  .chip-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: scroll;
    max-width: 100vw;
  }
  
  .chip::after {
    --m-i: linear-gradient(#000, #000);
    --m-o: content-box, padding-box;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: var(--border-width);
    border-radius: var(--border-radius);
    background-image: conic-gradient(
      #488cfb,
      #29dbbc,
      #ddf505,
      #ff9f0e,
      #e440bb,
      #655adc,
      #488cfb
    );
  
    mask-image: var(--m-i), var(--m-i);
    mask-origin: var(--m-o);
    mask-clip: var(--m-o);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  
    filter: hue-rotate(0);
    animation: hue linear 1000ms infinite;
    animation-play-state: paused;
  }
  
  .chip:hover::after {
    animation-play-state: running;
  }
  